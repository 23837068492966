// @flow
import * as React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import * as v from '../../config/variables'

import GlobalStyle from './GlobalStyle'
import Header from './Header'
import Footer from './Footer'

type Props = {
  header?: boolean,
  hideSiteId?: boolean,
  darkBackground?: boolean,
  children: React.Node,
}

const Display = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const Main = styled.main`
  min-height: 50vh;
  background-color: ${({ dark }) => (dark ? v.brandColor : v.gray200)};
  padding-bottom: 2rem;
  flex: 1 0 auto;
`

const Layout = ({
  children,
  header = true,
  hideSiteId = false,
  darkBackground = false,
}: Props) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <Display>
        <GlobalStyle />
        {header && (
          <Header
            siteTitle={data.site.siteMetadata.title}
            hideSiteId={hideSiteId}
          />
        )}
        <Main dark={darkBackground}>{children}</Main>
        <Footer />
      </Display>
    )}
  />
)

export default Layout
