// @flow

import styled from 'styled-components'
import * as v from '../../config/variables'

// type Props = { children: any }

export const HeadlineReview = styled.h1`
  /* margin-top: 0;
  margin-bottom: 0.5rem !important; */
  margin: 1rem 0 3rem;
  font-style: italic;

  &:before {
    content: '“';
  }

  &:after {
    content: '”';
  }
`

export const EmptyResultText = styled.p`
  color: ${v.gray600};
  font-size: 0.8rem;
  font-style: italic;
  margin: 0 0 1rem;
`
