// @flow

import styled from 'styled-components'
import * as v from '../../config/variables'

// type Props = { children: any }

export const Button = styled.button`
  display: inline-block;
  padding: 0.5rem 1.3rem;
  background-color: ${v.brandColor};
  border: none;
  border-radius: 3px;
  /* border-radius: ${v.borderRadiusDefault}; */
  /* border: 1px solid ${v.black}; */
  color: ${v.white};
  cursor: pointer;
  text-align: center;

  &:hover,
  &:focus {
    color: ${v.white};
    text-decoration: none;
  }

  &[disabled],
  &:disabled {
    cursor: default;
    /* opacity: 0.5; */
    background-color: ${v.grayLight}
    /* border-color: ${v.gray}; */
    /* color: ${v.gray}; */
  }
`

export const ButtonLink = styled.button`
  display: inline-block;
  background-color: transparent;
  color: ${v.textColor};
  /* font-weight: bold; */
  border-radius: 50px;
  padding: 0.2rem 1.4rem;
  border: 2px solid ${v.textColor};
  cursor: pointer;

  &:active {
    background-color: transparent;
    color: darken(${v.white}, 5%);
  }
`

export const ButtonBlank = styled.button`
  cursor: pointer;
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  font-smoothing: inherit;
  font-smoothing: inherit;
  -webkit-appearance: none;

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
`
