// @flow
import React from 'react' // , { useState }
import { Link, graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { transparentize } from 'polished'
import { Container as ContainerGeneric } from 'reactstrap'

// Redux
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ActionCreators from '../../store/actions'

import * as v from '../../config/variables'
// import { ButtonBlank } from '../UI'
// import MobileMenu from './MobileMenu'

type Props = {
  hideSiteId?: boolean,
  siteTitle: string,
  user: Object,
  logoutUser: Function,
}

const StyledHeader = styled.header`
  background: ${v.brandColor};
  height: 70px;
  margin-bottom: 0;
  color: ${v.white};

  @media (min-width: 576px) {
    height: 80px;
  }

  a {
    &:hover,
    &:focus {
      box-shadow: none;
    }
  }
`

const SiteId = styled.div`
  width: 170px;
  flex: 0 0 auto;

  img {
    margin: 0;
  }
`

const SiteIdLink = styled(Link)`
  display: flex !important;
  align-items: center;
`

const Title = styled.span`
  color: ${v.white};
  font-weight: bold;
  margin-left: 0.5rem;
  text-transform: uppercase;
`

const Logo = styled(Img)`
  max-width: 200px;
  /* margin: 1rem 0; */
`

const Container = styled(ContainerGeneric)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

// const Inner = styled.div``

const NavMenu = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  position: relative;
  /* top: 2px; */
  margin: 0 -0.7rem;
`

const NavItem = styled.li`
  margin: 0;
`

const NavLink = styled(Link)`
  height: 100%;
  margin: 0 0.7rem;
  /* padding: 1rem 0; */
  color: ${v.white};
  font-family: ${v.fontFamilyAccent};
  font-weight: bold;
  /* font-size: 1.1rem; */
  background-color: transparent;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
`

const DesktopMenu = styled.nav`
  /* display: flex; */
  display: flex;
  height: 100%;

  /* @media (min-width: 576px) {
    display: flex;
  } */
`

const Username = styled.span`
  color: ${transparentize(0.3, v.white)};
  font-size: 0.7rem;
  white-space: pre;
  font-family: ${v.fontFamilyBase};
  display: block;
  position: absolute;
  top: 46px;
  right: 12px;

  @media (min-width: 576px) {
    top: 50px;
  }
`

// const MobileMenuButton = styled(ButtonBlank)`
//   color: ${v.brandColor};
//   font-family: ${v.fontFamilyAccent};
//   font-weight: bold;
//   font-size: 1.1rem;
//   text-transform: uppercase;
//   padding: 1rem 0.5rem;

//   @media (min-width: 576px) {
//     display: none;
//   }
// `

const Header = ({ siteTitle, user, logoutUser, hideSiteId = false }: Props) => {
  // const [isOpen, setIsOpen] = useState(false)
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "app-logo-white.png" }) {
        childImageSharp {
          fixed(width: 40, height: 40) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  const { logo } = data
  const { isLoggedIn, firstName, lastName } = user
  const fullName = `${firstName} ${lastName}`

  const handleLogoutUser = e => {
    e.preventDefault()
    logoutUser()
    // navigate('/')
  }

  return (
    <StyledHeader>
      <Container>
        {!hideSiteId && (
          <>
            <SiteId>
              <SiteIdLink to="/" style={{ display: 'block' }}>
                <Logo alt={siteTitle} fixed={logo.childImageSharp.fixed} />
                <Title>Aufgaben</Title>
              </SiteIdLink>
            </SiteId>
            {/* 
        <MobileMenuButton onClick={() => setIsOpen(!isOpen)}>
          Menü
        </MobileMenuButton>
        <MobileMenu
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          onLogout={handleLogoutUser}
          isLoggedIn={isLoggedIn}
          username={username}
        />
        */}
            <DesktopMenu>
              <NavMenu>
                {/* <NavItem>
                  <NavLink to="/app/aufgaben">Aufgaben</NavLink>
                </NavItem> */}

                {/* <NavItem>
              <NavLink to="/app/verfassen">Verfassen</NavLink>
            </NavItem> */}

                {!isLoggedIn && (
                  <NavItem>
                    <NavLink to="/login">Login</NavLink>
                  </NavItem>
                )}

                {isLoggedIn && (
                  <>
                    <NavItem>
                      <NavLink as="a" onClick={e => handleLogoutUser(e)}>
                        Logout
                      </NavLink>
                      <Username>{fullName}</Username>
                    </NavItem>
                  </>
                )}
              </NavMenu>
            </DesktopMenu>
            {/* </Inner> */}
          </>
        )}
      </Container>
    </StyledHeader>
  )
}

function mapStateToProps({ user }) {
  return { user }
}

function mapDispatchToProps(dispatch: Function) {
  return {
    dispatch,
    ...bindActionCreators(ActionCreators, dispatch),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header)
