// @flow
import React from 'react'
// import { Link } from 'gatsby'
import styled from 'styled-components'

import { Container as ContainerGeneric, Row, Col } from 'reactstrap'
import * as v from '../../config/variables'

const StyledFooter = styled.footer`
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-start;
  padding-top: 2rem;
  padding-bottom: 2rem;
  /* margin-top: 2rem; */
  background-color: ${v.brandColor};
  color: ${v.white};

  a {
    color: ${v.white};
  }
`

const Inner = styled(ContainerGeneric)`
  /* display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-start; */
  text-align: center;
  font-size: 0.8rem;

  p {
    margin: 0 0 0.5rem;

    &:last-child {
      margin: 0;
    }
  }

  @media (min-width: 576px) {
    text-align: left;
  }

  @media (min-width: 768px) {
    /* justify-content: space-between; */
  }
`

const Copyright = styled.p``

const FooterMenuList = styled.ul`
  list-style-type: none;
  display: flex;
  margin: 0;
  padding: 0;
  justify-content: center;

  @media (min-width: 576px) {
    justify-content: flex-end;
  }

  > li {
    margin: 0 0.5rem;
    > a {
    }
  }
`

const Footer = () => (
  <StyledFooter id="footer-global">
    <Inner>
      <Row>
        <Col sm="6">
          <p>
            Entwickelt von{' '}
            <a
              href="https://www.tim-dienstplaner.de"
              title="Zu TiM Dienstplaner"
            >
              www.tim-dienstplaner.de
            </a>
          </p>
          <Copyright>
            © {new Date().getFullYear()},{' '}
            <a href="https://www.tim-dienstplaner.de">TiM Dienstplaner</a>
          </Copyright>
        </Col>
        <Col sm="6">
          <FooterMenuList>
            <li>
              <a href="https://www.tim-dienstplaner.de/Impressum">Impressum</a>
            </li>
            <li>
              <a href="https://www.tim-dienstplaner.de/Datenschutz">
                Datenschutz
              </a>
            </li>
          </FooterMenuList>
        </Col>
      </Row>
      {/* <FooterLogo
        alt="leicht+luftig Logo"
        src={require('../../images/logo/ll-logo.svg')}
      />

      <FooterMenuList>
        <li>
          <Link to="/datenschutz">Datenschutz</Link>
        </li>
        <li>
          <Link to="/impressum">Impressum</Link>
        </li>
      </FooterMenuList> */}
    </Inner>
  </StyledFooter>
)

export default Footer
