// @flow
import { store } from '../store/configureStore'

export const loggedIn = () => {
  const state = store.getState()
  return ((state || {}).user || {}).isLoggedIn
}

export const isMe = (userId: number) => {
  const state = store.getState()
  return ((state || {}).user || {}).id === userId
}

export const isGuest = () => {
  const USER_ROLE_GUEST = 5
  const state = store.getState()
  return state.userPermissions.isLoaded &&
    state.userPermissions.permissions[0].role
    ? state.userPermissions.permissions[0].role === USER_ROLE_GUEST
    : false
}

export const isController = () => {
  const USER_ROLE_CONTROLLER = 6
  const state = store.getState()
  return state.userPermissions.isLoaded &&
    state.userPermissions.permissions[0].role
    ? state.userPermissions.permissions[0].role === USER_ROLE_CONTROLLER
    : false
}

export const userHasEditorLevel = () => {
  const state = store.getState()
  const userRole =
    state.userPermissions.isLoaded && state.userPermissions.permissions[0].role
  return userRole === 1 || userRole === 3
}
